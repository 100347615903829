<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(createUserFunction)">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      User Details:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <b-form-group label-cols-sm="3" id="input-group-1" label="Name" label-for="input-1">
                          <b-form-input
                            v-model="name"
                            id="input-1"
                            type="text"
                            name="name"
                            class="form-control form-control-lg form-control-solid"
                          >
                          </b-form-input>
                        </b-form-group>

                        <validation-provider rules="required|email" name="email" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Email"
                            label-for="input-2"
                            description="Email will be used as login username"
                          >
                            <b-input-group size="lg">
                              <b-input-group-prepend>
                                <span class="input-group-text">
                                  <i class="la la-at"></i>
                                </span>
                              </b-input-group-prepend>
                              <b-form-input
                                v-model="username"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="email"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider rules="required" name="role" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="role-label" label="Role" label-for="role">
                            <b-form-checkbox-group
                              v-model="roles"
                              :options="['LOGISTICS', 'ADMIN']"
                              name="role"
                              stacked
                              :state="errors[0] ? false : valid ? true : null"
                            ></b-form-checkbox-group>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <!-- <button type="submit" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">
                        Submit
                      </button> -->
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUser } from "@/api/logistics.api";
import { useNotify } from "@/utils/notify";

import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "createUser",
  components: { ValidationProvider, ValidationObserver },
  setup(_, context) {
    const { notify } = useNotify(context);

    const name = ref("");
    const username = ref("");
    const roles = ref([]);

    const loading = ref(false);

    const createUserFunction = async function() {
      loading.value = true;
      const payload = {
        name: name.value,
        username: username.value,
        roles: roles.value
      };
      try {
        await createUser(payload);
        notify("Account created", `The account is created`);
        context.root.$router.push({ name: "ListUser" });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      name,
      username,
      roles,

      createUserFunction,
      loading
    };
  }
};
</script>
